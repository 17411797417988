* {
    font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
}

a {
    text-decoration:none;
    color:inherit;
}
.notbold{
    font-weight: normal;
}

 #Home {
    list-style: none;
    padding:0;
    margin: 0;
}

 body {
     background-color:#212127;
     color: #dedbd6;
 }

 #NoMargin {
     margin-top: 0;
 }

 ul {
     padding-left: 20px;
     list-style: circle;
 }

@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}

@media (min-width: 1200px) {
    .App {
        padding-top: 100px;
        padding-left: 100px;
        text-align: left;
        float: left;
        font-size: x-large;
        animation: fadein 1s;
        -moz-animation: fadein 1s; /* Firefox */
        -webkit-animation: fadein 1s; /* Safari and Chrome */
        -o-animation: fadein 1s; /* Opera */
    }

    h3 {
        margin-top: 0;
        margin-bottom: 0;
    }

    h4 {
        margin-top: 6px;
    }

    .info {
        transition: height 500ms 0ms, opacity 500ms 500ms;
        font-size: large;
        animation: fadein 1s;
        -moz-animation: fadein 1s; /* Firefox */
        -webkit-animation: fadein 1s; /* Safari and Chrome */
        -o-animation: fadein 1s; /* Opera */
        padding-left:185px;
        padding-top: 50px;
        line-height: 27px;
        margin-right: 100px;
    }
}

@media (max-width: 1200px) {
    .App {
        padding-top: 10px;
        padding-left: 20px;
        text-align: left;
        font-size: x-large;
        animation: fadein 1s;
        -moz-animation: fadein 1s; /* Firefox */
        -webkit-animation: fadein 1s; /* Safari and Chrome */
        -o-animation: fadein 1s; /* Opera */
    }

    .info {
        margin-top: 15px;
        font-size: large;
        animation: fadein 1s;
        -moz-animation: fadein 1s; /* Firefox */
        -webkit-animation: fadein 1s; /* Safari and Chrome */
        -o-animation: fadein 1s; /* Opera */
        padding-left: 10px;
        line-height: 27px;
        margin-right: 3px;
    }
    h3 {
        margin-bottom: 0;
    }
    h4 {
        margin-top: 6px;
    }
}

.Hover {
    background-image: linear-gradient(to right, #212127 50%, #4c4d54 50%);
    background-size: 200.5%;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    transition: background-position 0.5s, color .5s linear;
}

.Hover:hover{
    background-position: -100%;
}
